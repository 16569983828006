<template>
  <v-container>
    <page-title icon="mdi-sword-cross" title="Journée découverte" />

    <v-row>
      <v-col>
        <h2 class="text-center font-weight-regular">
          Arts martiaux celtes
        </h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h2 class="text-center font-weight-regular">
          Dimanche 18 mai 2025 de 9h00 à 17h00
        </h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h2 class="text-center font-weight-regular">
          Salle de gym de Botyre, Ayent
        </h2>
      </v-col>
    </v-row>

    <v-row class="mb-5">
      <v-col>
        <h2 class="text-center font-weight-regular">
          <v-icon x-large color="primary" class="mr-md-5">mdi-link</v-icon>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSdhg0PyNc4HN4Oeee3J6Q8HBid3XbhQkyz7JYykVJmOWP_w1Q/viewform?usp=sharing" target="_blank"
            >Formulaire d'inscription</a
          >
        </h2>
      </v-col>
    </v-row>

    <inline-image-card
      position="left"
      title="À qui s'adresse cette journée ?"
      :img-src="require('@/assets/img/training_course_2.jpg')"
    >
      <p>
        À toutes les personnes intéressées à découvrir un art martial original,
        <strong>aux sources de la tradition européenne.</strong>
      </p>
      <p>
        Aucune expérience en sport de combat ou art martial n'est requise,
        <strong>tout le monde est le bienvenu !</strong>
      </p>
    </inline-image-card>

    <inline-image-card
      position="right"
      title="Quel est le programme ?"
      :img-src="require('@/assets/img/training_course_3.jpg')"
    >
      <p>
        Durant cette journée, de courtes
        <strong>introductions historiques</strong> seront intégrées aux
        <strong>exercices pratiques</strong>.
      </p>
      <p>
        Vous aurez l'opportunité de découvrir le maniement des armes antiques
        de corps à corps comme <strong>le bouclier, l'épée et la lance</strong>
        mais aussi les armes de traits et de jets comme <strong>la javeline et la fronde</strong>.
      </p>
    </inline-image-card>

    <inline-image-card
      position="left"
      title="Comment s'inscrire ?"
      :img-src="require('@/assets/img/training_course_4.jpg')"
    >
      <p>
        Vous pouvez vous inscrire directement via ce
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSdhg0PyNc4HN4Oeee3J6Q8HBid3XbhQkyz7JYykVJmOWP_w1Q/viewform?usp=sharing" target="_blank"
          >formulaire en ligne</a
        >.
      </p>
      <p>
        Si vous n'êtes pas membre de l'association, les frais de participation
        au stage se montent à <strong>CHF 20.- pour la journée</strong>.
      </p>
      <p>
        Nous vous confirmerons par retour d'e-mail votre inscription et vous
        transmettrons toutes les informations nécessaires quelques jours avant la journée découverte.
      </p>
    </inline-image-card>

    <inline-image-card
      position="right"
      title="Vous avez des questions ?"
      :img-src="require('@/assets/img/training_course_5.jpg')"
    >
      <p>
        Vous pouvez nous poser vos questions par e-mail à
        <strong>info.seduni@gmail.com</strong>
        ou sur les réseaux sociaux.
      </p>
      <div class="d-flex flex-column flex-md-row justify-start mb-4">
        <facebook-link class="mr-3">Facebook</facebook-link>
        <instagram-link>Instagram</instagram-link>
      </div>
      <p>
        Nous nous réjouissons de vous rencontrer et de partager cette journée
        avec vous !
      </p>
    </inline-image-card>
  </v-container>
</template>

<script>
import FacebookLink from '@/components/FacebookLink.vue'
import InstagramLink from '@/components/InstagramLink.vue'

export default {
  components: { FacebookLink, InstagramLink },
  data() {
    return { publicPath: process.env.BASE_URL }
  }
}
</script>
